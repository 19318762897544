import React, { useState } from "react";
import RaceLogo from "/public/icons/RaceLogo.svg";
import Opensea from "/public/icons/Opensea.svg";
import Discord from "/public/icons/Discord.svg";
import Twitter from "/public/icons/Twitter.svg";
import Instagram from "/public/icons/Instagram.svg";
import { useRouter } from "next/router";
import classNames from "classnames";
import MenuIcon from "/public/icons/Menu.svg";
import Portal from "../Portal/Portal";

type NavItem = "Schedule" | "Roadmap" | "Compete" | "Rarity";

const NAV_ITEMS: { name: NavItem; path: string }[] = [
  { name: "Schedule", path: "/schedule" },
  { name: "Roadmap", path: "/roadmap" },
  { name: "Compete", path: "/compete" },
  { name: "Rarity", path: "/rarity" },
];

export type NavbarProps = {
  activeTab?: NavItem;
};

const Navbar: React.FunctionComponent<NavbarProps> = ({ activeTab }) => {
  const router = useRouter();
  const [collapsed, setCollapsed] = useState<Boolean>(false);

  return (
    <div className="bg-red w-full h-20 flex justify-center items-center sticky top-0 z-10">
      <div className="max-w-desktop w-full px-4 desktop:px-20 flex justify-between items-center">
        <RaceLogo
          className="cursor-pointer w-full"
          onClick={() => {
            router.push("/");
          }}
        />
        <div className="w-full items-center justify-end space-x-10 hidden desktop:flex">
          <ul className="flex space-x-10 items-center">
            {NAV_ITEMS.map((item, idx) => (
              <li
                className="cursor-pointer"
                key={`${item}-${idx}`}
                onClick={() => router.push(item.path)}
              >
                <p
                  className={classNames(
                    "text-white",
                    item.name !== activeTab && "text-opacity-40"
                  )}
                >
                  {item.name}
                </p>
              </li>
            ))}
          </ul>
          <ul className="flex space-x-10 items-center">
            <li>
              <a
                target="_blank"
                href="https://opensea.io/collection/mrvroom2022"
                rel="noreferrer"
              >
                <Opensea className="cursor-pointer" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://discord.gg/DXQgkqEp"
                rel="noreferrer"
              >
                <Discord className="cursor-pointer" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/mrvroomnft"
                rel="noreferrer"
              >
                <Twitter className="cursor-pointer" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/mrvroomnft"
                rel="noreferrer"
              >
                <Instagram className="cursor-pointer" />
              </a>
            </li>
          </ul>
        </div>
        <div
          className="fill-current text-white desktop:hidden"
          onClick={() => setCollapsed(!collapsed)}
        >
          <MenuIcon />
        </div>
        {collapsed && (
          <Portal selector="body">
            <div className="bg-red flex flex-col items-center justify-center space-y-20 w-screen h-screen fixed top-0 left-0 z-20">
              <ul className="flex flex-col space-y-10 items-center">
                {NAV_ITEMS.map((item, idx) => (
                  <li
                    className="cursor-pointer"
                    key={`${item}-${idx}`}
                    onClick={() => {
                      router.push(item.path);
                      setCollapsed(!collapsed);
                    }}
                  >
                    <p
                      className={classNames(
                        "text-white",
                        item.name !== activeTab && "text-opacity-40"
                      )}
                    >
                      {item.name}
                    </p>
                  </li>
                ))}
              </ul>
              <ul className="flex space-x-10 items-center">
                <li>
                  <a
                    target="_blank"
                    href="https://opensea.io/collection/mrvroom2022"
                    rel="noreferrer"
                  >
                    <Opensea className="cursor-pointer" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://discord.gg/DXQgkqEp"
                    rel="noreferrer"
                  >
                    <Discord className="cursor-pointer" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://twitter.com/mrvroomnft"
                    rel="noreferrer"
                  >
                    <Twitter className="cursor-pointer" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/mrvroomnft"
                    rel="noreferrer"
                  >
                    <Instagram className="cursor-pointer" />
                  </a>
                </li>
              </ul>
            </div>
          </Portal>
        )}
      </div>
    </div>
  );
};

export default Navbar;
